import { useLayoutEffect } from "react";

export const useScrollTo = ({ hash }) => {
  useLayoutEffect(() => {
    if (window && hash && hash.includes("#section-")) {
      const id = hash.replace("#", "");
      const el = document.getElementById(id);
      if (id !== "" && el) {
        setTimeout(() => {
          const top = window.scrollY + el.getBoundingClientRect().top;
          try {
            // trying to use new API - https://developer.mozilla.org/en-US/docs/Web/API/Window/scrollTo
            window.scroll({
              top: top,
              left: 0,
              behavior: "smooth",
            });
          } catch (error) {
            // just a fallback for older browsers
            window.scrollTo(0, top);
          }
        }, 1000);
      }
    }
  }, [hash]);
};

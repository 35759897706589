import { useLayoutEffect } from "react";

export const useScrollTop = ({ pathname, hash }) => {
  useLayoutEffect(() => {
    // <-- Now we return the useEffect teardown effect, which will be fired only after the path/search change for the first time
    if (["/united", "/falcons"].includes(pathname) && (!hash || hash === "")) {
      setTimeout(() => {
        try {
          // trying to use new API - https://developer.mozilla.org/en-US/docs/Web/API/Window/scrollTo
          window &&
            window.scroll({
              top: 0,
              left: 0,
              behavior: "auto",
            });
        } catch (error) {
          // just a fallback for older browsers
          window && window.scrollTo(0, 0);
        }
      }, 100);
    }
  }, [pathname, hash]);
};

import React from "react";
import PropTypes from "prop-types";
import { MDXProvider } from "@mdx-js/react";
import styled, { ThemeProvider } from "styled-components";
import Header from "~components/ui/header";
import GlobalStyles from "~styles/GlobalStyles";
import theme from "~styles/theme";
import { useScrollTop } from "~hooks/use-scroll-top";
import { useScrollTo } from "~hooks/use-scroll-to";
import { useLocation } from "@gatsbyjs/reach-router";
import CombinedLogos from "../icons/combined-logos-falcons";

const menuItems = [
  {
    name: "navlink-red-helmet",
    url: "/falcons#red-helmet",
    display: "RED HELMET",
  },
  {
    name: "navlink-team",
    url: "/falcons#our-team",
    display: "OUR TEAM",
  },
  {
    name: "navlink-fans",
    url: "/falcons#our-fans",
    display: "OUR FANS",
  },
  {
    name: "navlink-city",
    url: "/falcons#our-city",
    display: "OUR CITY",
  },
  {
    name: "navlink-purpose",
    url: "/falcons#our-purpose",
    display: "OUR PURPOSE",
  },
];

const FalconsLayout = ({ children }) => {
  const { pathname, hash } = useLocation();
  // force scrolling to top on load
  useScrollTop({ pathname, hash });
  // force scrolling to anchor on load after 1 sec
  useScrollTo({ hash });

  return (
    <ThemeProvider theme={theme("falcons")}>
      <MDXProvider>
        <GlobalStyles />

        <StyledLayout id='page-outer-wrapper'>
          <Header
            fixed={true}
            menuItems={menuItems}
            rootPath='/falcons'
            Logo={CombinedLogos}
          />
          {children}
        </StyledLayout>
      </MDXProvider>
    </ThemeProvider>
  );
};

const StyledLayout = styled.div`
  min-height: 100vh;
  min-height: -webkit-fill-available;
  /* overflow-x: hidden; */
  max-width: 100vw;
  /* box-sizing: content-box; */

  > header {
    display: block;
    max-width: 100vw;
  }
  > main {
    display: block;
    position: relative;
    margin: 0;
    padding: 0;
    min-height: 100vh;
    max-width: 100vw;
  }
`;

FalconsLayout.propTypes = {
  children: PropTypes.node,
};

export default FalconsLayout;

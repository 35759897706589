import React from "react";
import styled from "styled-components";

function CombinedLogos({
  title = "Atlanta Falcons Logo and American Family Insurance Logo",
  titleId = "combined-logos-svg",
  ...props
}) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={169.698}
      height={66.624}
      viewBox='0 0 169.698 66.624'
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <g data-name='Group 112'>
        <g data-name='Group 35'>
          <path
            data-name='Line 5'
            fill='none'
            stroke='#fff'
            strokeMiterlimit={10}
            strokeWidth={2}
            d='M78.423 0v66.624'
          />
          <g data-name='Group 26'>
            <g data-name='Group 25'>
              <g data-name='Group 24'>
                <path
                  data-name='Path 62'
                  d='M168.372 40.246a1.322 1.322 0 11-1.332 1.315 1.315 1.315 0 011.332-1.315zm0 2.449a1.128 1.128 0 10-1.091-1.134 1.09 1.09 0 001.091 1.134zm-.515-1.9h.6c.368 0 .54.144.54.441a.4.4 0 01-.406.422l.443.682h-.259l-.42-.665h-.255v.665h-.24zm.24.685h.251c.212 0 .407-.011.407-.255 0-.205-.177-.237-.339-.237h-.319v.492'
                  fill='#fff'
                />
                <g data-name='Group 23'>
                  <path
                    data-name='Path 63'
                    d='M167.32 25.635h-30.832l-20.726-5.182-22.084 6.005.563 2.1 21.522-5.849 20.435 5.109h31.122z'
                    fill='#fff'
                  />
                  <g data-name='Group 22'>
                    <path
                      data-name='Path 64'
                      d='M111.623 31.315h-1.993v.894h1.584v1.143h-1.584v1.051h1.985v1.142h-3.422v-5.369h3.43z'
                      fill='#fff'
                    />
                    <path
                      data-name='Rectangle 139'
                      fill='#fff'
                      d='M118.116 30.173h1.437v5.369h-1.437z'
                    />
                    <path
                      data-name='Rectangle 140'
                      fill='#fff'
                      d='M155.4 30.173h1.436v5.369H155.4z'
                    />
                    <path
                      data-name='Path 65'
                      d='M140.453 35.542h-1.432v-5.369h3.43v1.142h-1.993v.894h1.584v1.143h-1.584z'
                      fill='#fff'
                    />
                    <path
                      data-name='Path 66'
                      d='M158.276 35.542h3.422V34.18h-1.985v-4.007h-1.437z'
                      fill='#fff'
                    />
                    <path
                      data-name='Path 67'
                      d='M133.775 30.173v2.915l-1.443-2.915h-1.613v5.369h1.4v-2.915l1.443 2.915h1.614v-5.369z'
                      fill='#fff'
                    />
                    <path
                      data-name='Path 68'
                      d='M98.661 30.173l1.813 5.369H99l-.36-1.066h-1.494l-.36 1.066h-1.363l1.813-5.369zm-.365 3.162l-.4-1.194-.4 1.194z'
                      fill='#fff'
                    />
                    <path
                      data-name='Path 69'
                      d='M128.242 30.173l1.814 5.369h-1.474l-.36-1.066h-1.494l-.36 1.066h-1.363l1.813-5.369zm-.365 3.162l-.4-1.194-.4 1.194z'
                      fill='#fff'
                    />
                    <path
                      data-name='Path 70'
                      d='M145.798 30.173l1.813 5.369h-1.474l-.36-1.066h-1.495l-.36 1.066h-1.364l1.813-5.369zm-.365 3.162l-.4-1.194-.4 1.194z'
                      fill='#fff'
                    />
                    <path
                      data-name='Path 71'
                      d='M114.94 33.778l.662 1.764h1.384l-.821-2.186a1.519 1.519 0 00.821-1.489 1.653 1.653 0 00-.57-1.349 1.879 1.879 0 00-1.2-.345h-2.37v5.369h1.437v-1.764zm-.659-1.326v-1.078h.587a.777.777 0 01.408.074.544.544 0 01.224.461c0 .485-.374.543-.686.543z'
                      fill='#fff'
                    />
                    <path
                      data-name='Path 72'
                      d='M164.682 30.173l-1.036 1.968-1.036-1.968h-1.41l1.728 3.279v2.087h1.437v-2.087l1.728-3.283z'
                      fill='#fff'
                    />
                    <g data-name='Group 20'>
                      <path
                        data-name='Path 73'
                        d='M105.549 35.542v-2.425l-.95 2.425h-1.037l-.95-2.425v2.425h-1.393v-5.369h1.6l1.262 3.221 1.262-3.221h1.6v5.369z'
                        fill='#fff'
                      />
                    </g>
                    <g data-name='Group 21'>
                      <path
                        data-name='Path 74'
                        d='M152.567 35.542v-2.426l-.95 2.426h-1.037l-.95-2.426v2.426h-1.393v-5.369h1.6l1.262 3.221 1.262-3.221h1.6v5.369z'
                        fill='#fff'
                      />
                    </g>
                    <path
                      data-name='Path 75'
                      d='M123.952 31.688a1.083 1.083 0 00-.764-.339 1.542 1.542 0 000 3.018 1.085 1.085 0 00.765-.341l.751.916a2.287 2.287 0 01-1.663.749 2.861 2.861 0 010-5.666 2.286 2.286 0 011.663.749z'
                      fill='#fff'
                    />
                  </g>
                  <path
                    data-name='Path 76'
                    d='M95.423 36.62h70.638v6.27H95.423zm34.406 3.718l.672 1.115h1.417l-.833-1.382c.557-.192.833-.474.833-.941a.929.929 0 00-.578-.854 2.8 2.8 0 00-1.219-.22h-2.684v3.4h1.437v-1.115zm-.114-.839c.317 0 .7-.036.7-.344a.326.326 0 00-.227-.291 1.216 1.216 0 00-.413-.047h-.9v.681zm-28.5 1.954h1.437v-3.4h-1.435zm35.5-2.37h-.01l-.43 1.152h.87zm-2.445 2.37h1.534l.154-.391h1.5l.153.391h1.535l-1.556-3.4h-1.77zm17.665-1.323a.764.764 0 01-.844.585c-.527 0-.89-.271-.89-.961a.851.851 0 01.962-.961.7.7 0 01.772.595h1.627c-.19-1.347-1.53-1.451-2.4-1.451s-2.5.057-2.5 1.818 1.622 1.818 2.5 1.818a3.107 3.107 0 001.785-.385 1.569 1.569 0 00.614-1.057zm4.254 1.323h4.073v-.828h-2.631v-.457h2.457v-.827h-2.457v-.457h2.631v-.828h-4.073zm-50.444 0h1.38v-1.682h.011l1.534 1.683h1.581v-3.4h-1.382v1.664h-.014l-1.463-1.664h-1.648zm35.9 0h1.38v-1.682h.011l1.534 1.683h1.581v-3.4h-1.381v1.664h-.015l-1.463-1.664h-1.647zm-21.619-1.29c0 1.08.848 1.408 2.332 1.408s2.331-.328 2.331-1.408v-2.108h-1.442v2.056c0 .438-.418.576-.889.576s-.889-.138-.889-.576v-2.056h-1.443zm-4.439.076c.194.029.261.128.261.228 0 .162-.123.276-.573.276a1.265 1.265 0 01-.491-.071.259.259 0 01-.153-.243h-1.74c.005 1 1.218 1.142 2.328 1.142 1.622 0 2.348-.39 2.348-1.218 0-.575-.45-.89-1.177-.99l-1.724-.238c-.143-.019-.189-.081-.189-.176 0-.143.148-.243.578-.243a1.226 1.226 0 01.45.072.283.283 0 01.22.271h1.678c-.051-.718-.721-1.113-2.348-1.113-1.187 0-2.112.238-2.112 1.118 0 .476.291.857 1 .956z'
                    fill='#fff'
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
        <g data-name='Group 111'>
          <g data-name='Group 108'>
            <path
              data-name='Path 124'
              d='M54.571 17.804C43.734 11.726 16.9 7.675 16.9 7.675L5.406 19.991h11.918c-8.8 11.625-6.85 35.1 6.091 39.973a8.679 8.679 0 01.271-7.936c1.867-3.635 4.84-9.745 9.1-17.9h3.472c2.884 0 3.806 1.218 3.414 2.159 2.37 0 6.7-.232 9.85-4.047-2.619-3.662-11.284-7.929-11.284-7.929h14.863c2.184 0 3.276.777 2.973 2.093 3.793-1.858 2.806-6.184-1.503-8.6zm-6.148 14.432a7.485 7.485 0 01-4.489 2.676c1.284-.985 1.526-1.932.736-1.932h-2.839a1.615 1.615 0 00-.094 2.207 2.771 2.771 0 01-2.236-2.207h-6.012c.8-1.606 3.119-6.2 3.766-7.461l-.275-.812s9.582 4.684 11.443 7.529zm8.376-7.1c.022-1.132-1.088-1.974-3.1-1.974h-19.6l2.106 1.129s-12.611 25.11-13.58 27.091a8.333 8.333 0 00-.46 7 16.087 16.087 0 01-5.833-7.229l-1.119-.077 11.9-17.455a6.519 6.519 0 01-.068-.338L15.036 47.44a31.834 31.834 0 01-1.047-7.27l-1.361-.34 14.755-11.64c.036-.124.074-.248.114-.37l-13.38 8.12a33.729 33.729 0 011.273-6.639l-1.382-.784 15.748-5.248.211-.32-13.218 2.7a26.6 26.6 0 012.565-4.516l-1.225-1.154h15.808l.131-.261-23.534-1.8 6.692-7.2-1.04-1.327s28.864 4.231 37.978 9.23c4.191 2.3 3.914 5.47 2.675 6.518z'
              fill='#fff'
            />
            <path
              data-name='Path 125'
              d='M55.157 20.427l.252 1.113c-1.717.323-4.749.58-5.607.648l-.131.259h4.517c1.792 0 2.547.85 2.705 1.339a3.059 3.059 0 00-1.736-3.359z'
              fill='#fff'
            />
            <path
              data-name='Path 126'
              d='M52.624 20.803h1.618a12.537 12.537 0 00-4.108-2.575 14.971 14.971 0 012.49 2.575z'
              fill='#fff'
            />
            <path
              data-name='Path 127'
              d='M35.634 31.992h7.354c.054-.109.107-.217.161-.321l-6.823-1.019z'
              fill='#fff'
            />
            <path
              data-name='Path 128'
              d='M16.626 15.152l31.235 4.322.17-.335a282.473 282.473 0 00-29.493-6.056z'
              fill='#fff'
            />
            <path
              data-name='Path 129'
              d='M19.372 23.523l9.417-1.683c.081-.108.165-.217.25-.322l-8.482.192q-.637.873-1.185 1.813z'
              fill='#fff'
            />
            <path
              data-name='Path 130'
              d='M16.252 32.136l9.628-5.255c.052-.131.106-.261.161-.393l-9.263 3.328a30.41 30.41 0 00-.526 2.32z'
              fill='#fff'
            />
            <path
              data-name='Path 131'
              d='M18.531 50.475a16.879 16.879 0 001.358 2.171l6.642-13.62-.183-.338z'
              fill='#fff'
            />
            <path
              data-name='Path 132'
              d='M15.808 40.272q.091 1.266.282 2.5l8.692-9.694a8.216 8.216 0 01-.015-.416z'
              fill='#fff'
            />
            <g data-name='Group 107'>
              <path
                data-name='Path 133'
                d='M25.414 61.466l-.207-.041c-5.769-1.152-10.217-4.981-12.863-11.073-3.786-8.72-3.285-21.175 1.125-28.467l-13.47.288L15.831 5.156l.057.013c1.318.306 2.68.619 4.072.939 12.713 2.92 28.534 6.554 35.735 11.1 2.753 1.738 4.076 4.105 3.63 6.494a5.359 5.359 0 01-4.276 4.163l-.205.04.1-.186a1.367 1.367 0 00.069-1.337c-.241-.4-.895-.866-2.641-.866h-9.041c2.009 1.362 6.091 4.194 7.445 6.674l.03.054-.035.051c-1.077 1.578-4.474 5.261-12.736 5.366h-.22l.141-.169c.42-.5.557-.949.4-1.293-.246-.526-1.15-.816-2.546-.816h-1.792c-.516.987-7.782 14.884-9.116 17.468a8.536 8.536 0 00.421 8.427zM13.836 21.675l-.1.158c-3.917 6.327-5.44 18.695-1.208 28.438 2.586 5.955 6.909 9.725 12.509 10.912a8.606 8.606 0 01-.323-8.425 9014.286 9014.286 0 019.145-17.523l.029-.055h1.914c1.479 0 2.448.331 2.729.934a1.283 1.283 0 01-.291 1.339c7.925-.161 11.237-3.658 12.324-5.223-1.429-2.544-5.924-5.584-7.624-6.734l-.274-.185h9.7c1.457 0 2.43.333 2.814.962a1.438 1.438 0 01.026 1.347 5.145 5.145 0 003.919-3.959c.43-2.3-.86-4.594-3.54-6.286-7.172-4.524-22.975-8.154-35.672-11.071-1.372-.315-2.715-.623-4.016-.926L.471 21.959z'
                fill='#fff'
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

const StyledCombinedLogos = styled(CombinedLogos)`
  color: ${({ $color = "white", theme }) =>
    theme.colors[$color] ? theme.colors[$color] : $color};
`;

export default StyledCombinedLogos;
